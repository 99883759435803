import revive_payload_client_4sVQNw7RlN from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/ec2-user/www/BNTO-webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/ec2-user/www/BNTO-webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/ec2-user/www/BNTO-webapp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/home/ec2-user/www/BNTO-webapp/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/home/ec2-user/www/BNTO-webapp/.nuxt/element-plus-injection.plugin.mjs";
import plugin_1UohGbtF8v from "/home/ec2-user/www/BNTO-webapp/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _00_errorHandler_NA0RaHTYeK from "/home/ec2-user/www/BNTO-webapp/src/plugins/00.errorHandler.js";
import _00_hooks_E4NUmZ67HS from "/home/ec2-user/www/BNTO-webapp/src/plugins/00.hooks.js";
import _00_smartlook_client_KPDsruLeT8 from "/home/ec2-user/www/BNTO-webapp/src/plugins/00.smartlook.client.js";
import _03_init_2B4hNAj9HW from "/home/ec2-user/www/BNTO-webapp/src/plugins/03.init.js";
import _04_dynamic_aAWnnvYMjd from "/home/ec2-user/www/BNTO-webapp/src/plugins/04.dynamic.js";
import _05_v_console_client_6PmHA2QML5 from "/home/ec2-user/www/BNTO-webapp/src/plugins/05.v-console.client.js";
import _06_stripe_client_HMtlTmRMvD from "/home/ec2-user/www/BNTO-webapp/src/plugins/06.stripe.client.js";
import _07_viewport_height_dynamic_client_6EXJMOY9PD from "/home/ec2-user/www/BNTO-webapp/src/plugins/07.viewport-height-dynamic.client.js";
import _08_bnto_message_client_AgnJltQQSW from "/home/ec2-user/www/BNTO-webapp/src/plugins/08.bnto-message.client.js";
import _09_location_client_S2dz7nfoXB from "/home/ec2-user/www/BNTO-webapp/src/plugins/09.location.client.js";
import _10_google_analyze_client_7Zav6a5BEA from "/home/ec2-user/www/BNTO-webapp/src/plugins/10.google-analyze.client.js";
import _12_klaviyoListener_client_errpfSW2qj from "/home/ec2-user/www/BNTO-webapp/src/plugins/12.klaviyoListener.client.js";
import _13_logout_broadcast_client_4xTb51gB9F from "/home/ec2-user/www/BNTO-webapp/src/plugins/13.logout-broadcast.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_1UohGbtF8v,
  _00_errorHandler_NA0RaHTYeK,
  _00_hooks_E4NUmZ67HS,
  _00_smartlook_client_KPDsruLeT8,
  _03_init_2B4hNAj9HW,
  _04_dynamic_aAWnnvYMjd,
  _05_v_console_client_6PmHA2QML5,
  _06_stripe_client_HMtlTmRMvD,
  _07_viewport_height_dynamic_client_6EXJMOY9PD,
  _08_bnto_message_client_AgnJltQQSW,
  _09_location_client_S2dz7nfoXB,
  _10_google_analyze_client_7Zav6a5BEA,
  _12_klaviyoListener_client_errpfSW2qj,
  _13_logout_broadcast_client_4xTb51gB9F
]