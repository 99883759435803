import {defineStore} from "pinia";

export const useBaseStore = defineStore("base", {
  state: () => ({
    xBntoUa: "",
    xBntoAuth: "",
    deviceId: "",
    xBntoLocation: "",
    xBntoSource: "",
    apiVersion: "",
    sessionTimeStamp: "",
    appInit: {
      agreementLink: {
        // "ourStoryLink": "https://www.bnto.rent",
        // "buyFromViaViaLink": "https://www.viavia.live",
        // "faqSupportLink": "https://www.bnto.rent/landing/faq",
        // "contactUsLink": "https://www.bnto.rent",
        // "howItWorksLink": "https://www.bnto.rent/landing/how-it-works",
        // "privacyPolicyLink": "https://www.bnto.rent/landing/privacy-policy",
        // "termsConditionsLink": "https://www.bnto.rent/landing/terms-of-use",
        // "allStyleScheme": "bnto://plp?type=basic&title=AllStyle&name=allStyle"
      },
      popInfo: [],
      subscribeIntroduce: {
        // "skuItemsText": "6",
        // "priceText": "$98",
        // "bonusText": "$20+",
        // "descItems": [
        //   "You can rent 6 items for $98 each month*",
        //   "Free shipping + returns",
        //   "Pause or cancel any time",
        //   "Buy what you love at up to 75% off",
        //   "Laundry + dry cleaning included",
        //   "No late fees or damage fees",
        //   "Exclusive brands + unique styles",
        //   "Bonus Credits can be used to purchase products"
        // ]
      },
      sessionId: ""
    },
    pageLabel: "",
    viewportHeight: "",
    isMobile: false,
    isLogin: false,
    routeRecords: [],
    userInfo: {
      userId: "",
      email: "", // 登录邮箱
      isMember: "", // 0 非会员 1 会员
      subStatus: "", // 订阅状态 Subscribed, Paused, Cancelled
      pausedExpired: "", // 0 未过期 触发unPaused、1 已过期 触发resume Subscribe
      bntoUpperLimit: 6,
      bntoIcon: -1 // bnto图标的样式 0：数字 1：盒子 2：锁住
    },
    isShowModalFitAndPreferences: false,
    isShowModalDownload: false,
    isShowModalLoadingProgress: false,
    modalWaitlist: {
      visible: false,
      subtitle: "",
      title: "",
      text: "",
      btnVisible: true,
      btnLink: "",
    },
    isShowModalPremiumIntroduce: false,
    isShowModalSizeCharts: false,
    isShowModalShippingAddress: false,
    isShowModalPaymentInfo: false,
    isShowDrawerSearch: false,
    isShowDrawerMenu: false,
    isShowDrawerCartOrderPreview: false,
    isShowDrawerCartShipmentEdit: false,
    isShowDrawerAIMore: false,
    isShowDrawerAIFaq: false,
    drawerAiClearStatus: 0, // 0 未清理 1 清理中 2 已清理
    isShowFilter: false,
  }),
  getters: {
    getterXBntoUa(state) {
      return state.xBntoUa
    },
    getterXBntoAuth(state) {
      return state.xBntoAuth
    },
    getterDeviceId(state) {
      return state.deviceId
    },
    getterXBntoLocation(state) {
      return state.xBntoLocation
    },
    getterXBntoSource(state) {
      return state.xBntoSource
    },
    getterApiVersion(state) {
      return state.apiVersion
    },
    getterSessionTimeStamp(state) {
      return state.sessionTimeStamp
    },
    getterAppInit(state) {
      return state.appInit
    },
    getterPageLabel(state) {
      return state.pageLabel
    },
    getterViewportHeight(state) {
      return state.viewportHeight
    },
    getterIsMobile(state) {
      return state.isMobile
    },
    getterIsLogin(state) {
      return state.isLogin
    },
    getterRouteRecords(state) {
      return state.routeRecords
    },
    getterUserInfo(state) {
      return state.userInfo
    },
    getterIsShowModalFitAndPreferences(state) {
      return state.isShowModalFitAndPreferences
    },
    getterIsShowModalDownload(state) {
      return state.isShowModalDownload
    },
    getterIsShowModalLoadingProgress(state) {
      return state.isShowModalLoadingProgress
    },
    getterModalWaitlist(state) {
      return state.modalWaitlist
    },
    getterIsShowModalPremiumIntroduce(state) {
      return state.isShowModalPremiumIntroduce
    },
    getterIsShowModalSizeCharts(state) {
      return state.isShowModalSizeCharts
    },
    getterIsShowModalShippingAddress(state) {
      return state.isShowModalShippingAddress
    },
    getterIsShowModalPaymentInfo(state) {
      return state.isShowModalPaymentInfo
    },
    getterIsShowDrawerSearch(state) {
      return state.isShowDrawerSearch
    },
    getterIsShowDrawerMenu(state) {
      return state.isShowDrawerMenu
    },
    getterIsShowDrawerCartOrderPreview(state) {
      return state.isShowDrawerCartOrderPreview
    },
    getterIsShowDrawerCartShipmentEdit(state) {
      return state.isShowDrawerCartShipmentEdit
    },
    getterIsShowDrawerAIMore(state) {
      return state.isShowDrawerAIMore
    },
    getterIsShowDrawerAIFaq(state) {
      return state.isShowDrawerAIFaq
    },
    getterDrawerAiClearStatus(state) {
      return state.drawerAiClearStatus
    },
    getterIsShowFilter(state) {
      return state.isShowFilter
    },
  },
  actions: {
    updateXBntoUa(xBntoUa) {
      this.xBntoUa = xBntoUa
    },
    updateXBntoAuth(xBntoAuth) {
      this.xBntoAuth = xBntoAuth
    },
    updateDeviceId(deviceId) {
      this.deviceId = deviceId
    },
    updateXBntoLocation(xBntoLocation) {
      this.xBntoLocation = xBntoLocation
    },
    updateXBntoSource(xBntoSource) {
      this.xBntoSource = xBntoSource
    },
    updateApiVersion(apiVersion) {
      this.apiVersion = apiVersion
    },
    updateAppInit(appInit) {
      this.sessionTimeStamp = new Date().getTime()
      this.appInit = appInit
    },
    updatePageLabel(pageLabel) {
      this.pageLabel = pageLabel
    },
    updateViewportHeight(viewportHeight) {
      this.viewportHeight = viewportHeight
    },
    updateIsMobile(isMobile) {
      this.isMobile = isMobile
    },
    updateIsLogin(isLogin) {
      this.isLogin = isLogin
    },
    updateRouteRecords(routeRecords) {
      this.routeRecords = routeRecords
    },
    updateUserInfo(userInfo) {
      this.userInfo = userInfo
    },
    updateIsShowModalFitAndPreferences(isShowModalFitAndPreferences) {
      this.isShowModalFitAndPreferences = isShowModalFitAndPreferences
    },
    updateIsShowModalDownload(isShowModalDownload) {
      this.isShowModalDownload = isShowModalDownload
    },
    updateIsShowModalLoadingProgress(isShowModalLoadingProgress) {
      this.isShowModalLoadingProgress = isShowModalLoadingProgress
    },
    updateModalWaitlist(modalWaitlist) {
      this.modalWaitlist = modalWaitlist
    },
    updateIsShowModalPremiumIntroduce(isShowModalPremiumIntroduce) {
      this.isShowModalPremiumIntroduce = isShowModalPremiumIntroduce
    },
    updateIsShowModalSizeCharts(isShowModalSizeCharts) {
      this.isShowModalSizeCharts = isShowModalSizeCharts
    },
    updateIsShowModalShippingAddress(isShowModalShippingAddress) {
      this.isShowModalShippingAddress = isShowModalShippingAddress
    },
    updateIsShowModalPaymentInfo(isShowModalPaymentInfo) {
      this.isShowModalPaymentInfo = isShowModalPaymentInfo
    },
    updateIsShowDrawerSearch(isShowDrawerSearch) {
      this.isShowDrawerSearch = isShowDrawerSearch
    },
    updateIsShowDrawerMenu(isShowDrawerMenu) {
      this.isShowDrawerAIMore = false
      this.isShowDrawerAIFaq = false

      nextTick().then(() => {
        this.isShowDrawerMenu = isShowDrawerMenu
      })
    },
    updateIsShowDrawerCartOrderPreview(isShowDrawerCartOrderPreview) {
      this.isShowDrawerCartOrderPreview = isShowDrawerCartOrderPreview
    },
    updateIsShowDrawerCartShipmentEdit(isShowDrawerCartShipmentEdit) {
      this.isShowDrawerCartShipmentEdit = isShowDrawerCartShipmentEdit
    },
    updateIsShowDrawerAIMore(isShowDrawerAIMore) {
      this.isShowDrawerAIMore = isShowDrawerAIMore
    },
    updateIsShowDrawerAIFaq(isShowDrawerAIFaq) {
      this.isShowDrawerAIFaq = isShowDrawerAIFaq
    },
    updateDrawerAiClearStatus(drawerAiClearStatus) {
      this.drawerAiClearStatus = drawerAiClearStatus
    },
    updateIsShowFilter(isShowFilter) {
      this.isShowFilter = isShowFilter
    },
  },
  persist: {
    paths: ['isLogin', 'xBntoLocation']
  }
})
