import {defineNuxtPlugin, useCookie} from '#app';
import {useBaseStore} from "~/stores/base";
import {globalUpdateUserInfo} from "~/utils/common";
import Cookies from "js-cookie";
import {PATH_ACCOUNT, PATH_SUBSCRIBE} from "~/utils/constants";
import {smartLookProperties} from "~/utils/smartlook";

export default defineNuxtPlugin(async () => {
  const isLogin = useBaseStore().getterIsLogin
  console.log('[isLogin]', isLogin)
  if (isLogin) {
    if (process.client) {
      /**
       * 按需阻塞页面进程
       * 需求在 route-guard.global.js
       * **/
      const path = useRoute().path
      switch (true) {
        case path.includes(PATH_ACCOUNT):
        case path.includes(PATH_SUBSCRIBE):
          // 阻塞
          await globalUpdateUserInfo()
          break;
        default:
          // 非阻塞
          globalUpdateUserInfo()
          break;
      }
    }
  } else {
    if (process.client) {
      Cookies.remove("userInfo", { path: "/", domain: ".bnto.rent" });
      /** 未登录 初始化 smartlook 全局参数 **/
      smartLookProperties()
    }
    if (process.server) {
      const cookie = useCookie("userInfo")
      cookie.value = null
    }
  }
});
