import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function appDynamic() {
  return omniFetch({
    url: config.v1Path + config.appDynamicPath,
    method: "get",
    params: {}
  })
}

export function appInit() {
  return omniFetch({
    url: config.v1Path + config.appInitPath,
    method: "get",
    params: {}
  })
}

export function getIP() {
  return $fetch("https://api.ipify.org?format=json")
}

export function getLocation() {
  return $fetch("https://ipinfo.io?token=8f21fbc6e589e3")
}
